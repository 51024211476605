<template>
  <div>
    <!-- begin:: Header -->
    <div
      id="kt_header"
      ref="kt_header"
      class="header"
      v-bind:class="headerClasses"
    >
      <div
        class="container-fluid d-flex align-items-center justify-content-between"
      >
        <!-- begin:: Header Menu -->
        <div
          class="header-menu-wrapper header-menu-wrapper-left"
          ref="kt_header_menu_wrapper"
        >
          <div
            v-if="headerMenuEnabled"
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
            >
              <template>
                <li class="breadcrumb-item">
                  <span style="font-size: 12px;">
                    TRANSAKSI BANK / BANK KELUAR / <span style="font-weight: 600;">VOUCHER</span>
                  </span>
                </li>
              </template>
            </ul>
          </div>
        </div>
        <!-- end:: Header Menu -->
        <!-- begin:: Header Topbar -->
        <div class="topbar">
          <!--begin: User Bar -->
          <div class="topbar-item">
            <!--begin: Notifications -->
            <ApprovalNotification></ApprovalNotification>
            <!--end: Notifications -->
            <div
              class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <span
                class="symbol symbol-30 symbol-circle symbol-xl-30 pr-4 symbol-light-success"
              >
                <img v-if="false" alt="Profile Photo" :src="currentUserPhoto" />
                <span
                  v-if="true"
                  class="symbol-label font-size-h5 font-weight-bold"
                >
                  {{ user_fullname.charAt(0).toUpperCase() }}
                </span>
              </span>
              <span
                class="d-none d-md-inline mr-12"
                v-bind:style="{
                  'font-family': 'lato',
                  'font-size': '12px',
                  'font-weight': '600'
                }"
              >
                <span class="font-weight-bold font-size-base d-md-inline mr-1">
                  HI,
                </span>
                {{ user_fullname }}
              </span>
              <i class="ki ki-arrow-down icon-xs text-muted mr-12"></i>
            </div>

            <div
              id="kt_quick_user"
              ref="kt_quick_user"
              class="offcanvas offcanvas-right p-10"
            >
              <!--begin::Header-->
              <div
                class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
              >
                <h3 class="font-weight-bold m-0">
                  PROFIL USER
                </h3>
                <a
                  href="#"
                  class="btn btn-xs btn-icon btn-light btn-hover-primary"
                  id="kt_quick_user_close"
                >
                  <i class="ki ki-close icon-xs text-muted"></i>
                </a>
              </div>
              <!--end::Header-->

              <!--begin::Content-->
              <perfect-scrollbar
                class="offcanvas-content pr-5 mr-n5 scroll"
                style="max-height: 90vh; position: relative;"
              >
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                  <div class="symbol symbol-100 mr-5">
                    <img :src="currentUserPersonalInfo.photo" alt="" />
                    <i class="symbol-badge bg-success"></i>
                  </div>
                  <div class="d-flex flex-column">
                    <span class="font-weight-bold font-size-h5 text-dark-75">
                      {{ user_fullname }}
                    </span>
                    <div class="navi mt-2">
                      <span class="navi-link p-0 pb-2">
                        <span class="navi-icon mr-1">
                          <span class="svg-icon svg-icon-lg svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="assets/icons/Mail.svg" />
                            <!--end::Svg Icon-->
                          </span>
                        </span>
                        <span class="navi-text text-muted">
                          {{ email }}
                        </span>
                      </span>
                    </div>
                    <button
                      class="btn btn-light-primary btn-bold"
                      @click="onLogout"
                    >
                      SIGN OUT
                    </button>
                  </div>
                </div>
                <!--end::Header-->
                <div class="separator separator-dashed mt-8 mb-5"></div>
                <div class="separator separator-dashed my-7"></div>
              </perfect-scrollbar>
              <!--end::Content-->
            </div>
          </div>
          <!--end: User Bar -->
        </div>
        <!-- end:: Header Topbar -->
      </div>
    </div>
    <!-- end:: Header -->
    <!-- Body -->
    <div style="margin-bottom: 43px;">
      <div>
        <img class="mb-4" src="assets/background_page_title.png" alt="" style="height: 36px; margin-right: 10px;">
        <span style="font-size: 30px; font-weight: 700;">
          VOUCHER
        </span>
      </div>
      <div>
        <span>
          MENAMPILKAN DAN MEMBUAT DATA TRANSAKSI VOUCHER BARU
        </span>
      </div>
    </div>
    <div class="form-group">
      <template>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="white"
                light
                class="mb-10"
                v-bind="attrs"
                v-on="on"
                style="text-transform: capitalize !important; min-height: 48px;"
              >
                BUAT TRANSAKSI BARU +
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TANGGAL TRANSAKSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-menu
                          ref="date_voucher"
                          v-model="date_voucher"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          full-width
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formInputNotClear.bank_transaction_date"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#c30e0e !important'
                              }"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="bank_transaction_date"
                            no-title
                            @input="date_voucher = false"
                            @change="onChangeDate(bank_transaction_date)"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PERUSAHAAN<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.workgroup_id"
                          id="workgroup_id"
                          :items="data_workgroup_combobox"
                          @click="dataWorkgroup_Combobox"
                          item-value="workgroup_id"
                          item-text="workgroup_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >PROYEK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.project_id"
                          id="project_id"
                          :items="data_project_combobox"
                          @click="dataProject_Combobox"
                          item-value="project_id"
                          item-text="project_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >COST<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.financing_id"
                          id="financing_id"
                          :items="data_financings_combobox"
                          @click="dataFinancings_Combobox"
                          item-value="financing_id"
                          item-text="financing_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;">DESKRIPSI TRANSAKSI</span>
                      </v-col>
                      <v-col cols="9">
                        <v-textarea
                          v-model="formInput.bank_transaction_desc"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >BANK<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9" v-if="seen_made_from == 'import'">
                        <v-select
                          v-model="formInput.bank_id"
                          id="bank_id"
                          :items="data_bank_combobox"
                          @click="dataBank_Combobox"
                          item-value="bank_id"
                          item-text="bank_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                          readonly
                        ></v-select>
                      </v-col>
                      <v-col cols="9" v-else>
                        <v-select
                          v-model="formInput.bank_id"
                          id="bank_id"
                          :items="data_bank_combobox"
                          @click="dataBank_Combobox"
                          item-value="bank_id"
                          item-text="bank_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >NOMOR REKENING<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9" v-if="seen_made_from == 'import'">
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                          readonly
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="9" v-else>
                        <v-autocomplete
                          v-model="formInput.account_number"
                          id="account_number"
                          :items="data_bank_account_combobox"
                          @click="dataBankAccount_Combobox"
                          item-value="account_number"
                          item-text="account_number"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >JENIS TRANSAKSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9" v-if="seen_made_from == 'import'">
                        <v-select
                          v-model="formInput.bank_transaction_type"
                          id="bank_transaction_type_id"
                          :items="data_bank_transaction_type_combobox"
                          @change="seenDetailType"
                          item-value="bank_transaction_type_id"
                          item-text="bank_transaction_type_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                          readonly
                        ></v-select>
                        <v-row v-if="seen === 'C'">
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;"
                              >NO CEK<span style="color: red;">*</span></span
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="formInput.cheque_number"
                              id="cheque_number"
                              :items="data_cheque_combobox"
                              @click="dataCheque_Combobox"
                              item-value="cheque_number"
                              item-text="cheque_number"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#1e2758 !important'
                              }"
                              readonly
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else-if="
                            seen === 'IB' || seen === 'T' || seen === 'D'
                          "
                        >
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;"
                              >UPLOAD BUKTI<span style="color: red;"
                                >*</span
                              ></span
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-file-input
                              accept="image/*,application/pdf"
                              type="file"
                              @change="onImageChange"
                              v-model="formInput.path_image_transaction_type"
                              show-size
                              counter
                              :clearable="false"
                              readonly
                            ></v-file-input>
                            <v-div
                              v-if="
                                this.add_voucher.path_image_transaction_type2 !=
                                  null
                              "
                            >
                              <v-img
                                :src="add_voucher.path_image_transaction_type2"
                              ></v-img>
                            </v-div>
                            <v-div v-else>
                              <v-img
                                :src="editedItem.path_image_transaction_type"
                              ></v-img>
                            </v-div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="9" v-else>
                        <v-select
                          v-model="formInput.bank_transaction_type"
                          id="bank_transaction_type_id"
                          :items="data_bank_transaction_type_combobox"
                          @change="seenDetailType"
                          item-value="bank_transaction_type_id"
                          item-text="bank_transaction_type_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                        <v-row v-if="seen === 'C'">
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;"
                              >NO CEK<span style="color: red;">*</span></span
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-select
                              v-model="formInput.cheque_number"
                              id="cheque_number"
                              :items="data_cheque_combobox"
                              @click="dataCheque_Combobox"
                              item-value="cheque_number"
                              item-text="cheque_number"
                              class="vuetify-custom"
                              solo
                              v-bind:style="{
                                color: '#1e2758 !important'
                              }"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row
                          v-else-if="
                            seen === 'IB' || seen === 'T' || seen === 'D'
                          "
                        >
                          <v-col cols="3" class="mt-4">
                            <span style="color: black; font-size: 14px;"
                              >UPLOAD BUKTI<span style="color: red;"
                                >*</span
                              ></span
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-file-input
                              accept="image/*,application/pdf"
                              type="file"
                              @change="onImageChange"
                              v-model="formInput.path_image_transaction_type"
                              show-size
                              counter
                              :clearable="false"
                            ></v-file-input>
                            <v-div
                              v-if="
                                this.add_voucher.path_image_transaction_type2 !=
                                  null
                              "
                            >
                              <v-img
                                :src="add_voucher.path_image_transaction_type2"
                              ></v-img>
                            </v-div>
                            <v-div v-else>
                              <v-img
                                :src="editedItem.path_image_transaction_type"
                              ></v-img>
                            </v-div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-8">
                        <span style="color: black; font-size: 14px;"
                          >NOMINAL<span style="color: red;">*</span></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <vuetify-money
                          v-model="formInput.amount"
                          v-bind:placeholder="placeholder"
                          v-bind:readonly="readonly"
                          v-bind:disabled="disabled"
                          v-bind:outlined="outlined"
                          v-bind:clearable="clearable"
                          v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                          v-bind:options="option"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3" class="mt-4">
                        <span style="color: black; font-size: 14px;"
                          >TIPE TRANSAKSI<span style="color: red;"
                            >*</span
                          ></span
                        >
                      </v-col>
                      <v-col cols="9">
                        <v-select
                          v-model="formInput.transaction_type"
                          id="transaction_type_id"
                          :items="data_transaction_type_combobox"
                          item-value="transaction_type_id"
                          item-text="transaction_type_name"
                          class="vuetify-custom"
                          solo
                          v-bind:style="{
                            color: '#1e2758 !important'
                          }"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="mb-4 btn btn-outline-secondary"
                  @click="close"
                >
                  BATALKAN
                </v-btn>
                <v-btn
                  color="primary"
                  light
                  class="mb-4"
                  @click="formSubmit"
                  style="text-transform: capitalize !important; color: white !important;"
                >
                  SIMPAN TRANSAKSI BARU
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="CARI VOUCHER"
            solo
            hide-details
            class="mb-12"
            style="width: 10px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          ></v-text-field>
          <v-dialog v-model="dialogSeenTransactionType" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-col>
                    <v-col class="text-center" v-for="(data) in data_files" :key="data.bank_transaction_id">
                      <img :src="data.path_image_transaction_type" alt="Foto" style="max-width: 700px;" />
                    </v-col>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogSeenTransactionType = false">
                  TUTUP
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >APAKAH YAKIN UNTUK MENGHAPUS DATA INI?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDelete">BATAL</v-btn>
                <v-btn
                  text
                  @click="deleteDataVoucher"
                  v-bind:style="{
                    color: '#1e2758 !important'
                  }"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <div>
          <input
            accept="application/*"
            type="file"
            @change="handleFileUpload"
            show-size
            counter
            class="py-1 mb-3"
            style="width: 165px; box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);"
          />
          <v-btn color="blue" light small class="ml-3" @click="importDataVoucher" data-toggle="tooltip" style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;">
            <v-span small style="color: #ffffff;">
              IMPORT DATA
            </v-span>
          </v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="data_voucher"
          :item-key="bank_transaction_id"
          :options.sync="options"
          :search="search"
          sort-by="Id"
          class="elevation-1 mb-12"
          :loading="loadingTable"
          loading-text="HARAP TUNGGU..."
        >
          <template v-slot:[`item.number`]="{ index }">
            <td>
              {{
                options.page * options.itemsPerPage -
                  options.itemsPerPage +
                  (index + 1)
              }}
            </td>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <td>Rp {{ formatNumber(item.amount) }}</td>
          </template>
          <template v-slot:[`item.transaction_type`]="{ item }">
            <td>
              {{ item.transaction_type == "KBKM" ? "KAS BESAR" : "UMUM" }}
            </td>
          </template>
          <template v-slot:[`item.bank_transaction_type`]="{ item }">
            <td v-if="item.bank_transaction_type === 'C'">Cek</td>
            <td v-else-if="item.bank_transaction_type === 'IB'">
              Internet Banking
            </td>
            <td v-else-if="item.bank_transaction_type === 'T'">Transfer</td>
            <td v-else-if="item.bank_transaction_type === 'D'">Debet</td>
            <td v-else>-</td>
          </template>
          <template v-slot:[`item.path_image_transaction_type`]="{ item }">
            <div v-if="item.cheque_number == null">
              <v-btn
                color="blue"
                light
                small
                class="mr-3"
                @click="seenFileTransactionType(item)"
                data-toggle="tooltip"
                title="LIHAT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.upload_status == 1"
              >
                <v-icon small color="white">
                  mdi-eye
                </v-icon>
              </v-btn>
            </div>
            <div v-else>
              <td>{{ item.cheque_number_with_void }}</td>
            </div>
          </template>
          <template v-slot:[`item.financing_name`]="{ item }">
            <td>
              {{ item.financing_name === null ? "-" : item.financing_name }}
            </td>
          </template>
          <template v-slot:[`item.posted_by`]="{ item }">
            <td>
              {{ item.posted_by == "" ? "-" : item.posted_by }}
            </td>
          </template>
          <template v-slot:[`item.date_posted`]="{ item }">
            <td>
              {{ item.date_posted == null ? "-" : item.date_posted }}
            </td>
          </template>
          <template v-slot:[`item.approval_by`]="{ item }">
            <td>
              {{ item.approval_by == "" ? "-" : item.approval_by }}
            </td>
          </template>
          <template v-slot:[`item.date_approval`]="{ item }">
            <td>
              {{ item.date_approval == null ? "-" : item.date_approval }}
            </td>
          </template>
          <template v-slot:[`item.aksi`]="{ item }">
            <div v-if="user_role.toLowerCase() === 'atasan' || user_role.toLowerCase() === 'atasan keuangan'">
              <v-btn
                color="blue"
                light
                small
                class="mr-3"
                @click="updateDataVoucherApproval(item)"
                data-toggle="tooltip"
                title="APPROV"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.approval_status === 0"
              >
                <v-span small style="color: #ffffff;">
                  APPROV
                </v-span>
              </v-btn>
              <v-icon small color="black" v-if="item.approval_status === 1">
                flaticon2-line
              </v-icon>
            </div>
            <div v-else-if="user_role.toLowerCase() === 'atasan operasional'">
              <v-icon small color="black">
                flaticon2-line
              </v-icon>
            </div>
            <div v-else>
              <v-btn
                color="blue"
                light
                small
                class="mr-3"
                @click="clickPosted(item)"
                data-toggle="tooltip"
                title="POSTING"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.approval_status === 1 && item.post_status === 0"
              >
                <v-span small style="color: #ffffff;">
                  POSTING
                </v-span>
              </v-btn>
              <v-btn
                color="green"
                light
                small
                class="mr-3"
                @click="editItem(item)"
                data-toggle="tooltip"
                title="EDIT"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.approval_status === 0"
              >
                <v-icon small color="white">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="red"
                light
                small
                @click="deleteItem(item)"
                data-toggle="tooltip"
                title="HAPUS"
                style="text-transform: capitalize !important; min-width: 0px; padding: 0 6px;"
                v-if="item.approval_status === 0"
              >
                <v-icon small color="white">
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-icon small color="black" v-if="item.post_status === 1">
                flaticon2-line
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </template>
    </div>
    <!-- End Body -->
  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<style scoped>
.vuetify-custom >>> .v-text-field__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-select__slot label {
  color: #1e2758 !important;
}

.vuetify-custom >>> .v-input__icon i {
  color: #1e2758 !important;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import ApprovalNotification from "@/view/pages/dashboard/ApprovalNotificationBell.vue";
import localStorage from "@/core/services/store/localStorage";
import Services from "@/core/services/app_internal/Services";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import "@/core/plugins/vuetify-money.js";
import { formatNumber } from "@/helpers/helper.js";
import { formatDate } from "@/helpers/helper.js";
import { read, utils } from 'xlsx';
import axios from 'axios';

export default {
  name: "bank-out",

  components: {
    ApprovalNotification
  },

  data: () => ({
    search: "",
    dialog: false,
    dialogDelete: false,
    dialogDeleteDetail: false,
    dialogSeenTransactionType: false,
    create_data_voucher: [],
    create_data_bank_guarantee: [],
    update_data_voucher: [],
    delete_data_voucher: [],
    data_voucher: [],
    data_escrow_notification: [],
    data_bank_guarantee_notification: [],
    data_submission_form_finance_notification: [],
    data_workgroup_combobox: [],
    data_project_combobox: [],
    data_financings_combobox: [],
    data_bank_combobox: [],
    data_bank_account_combobox: [],
    data_cheque_combobox: [],
    data_files: [],
    data_bank_transaction_type_combobox: [
      {
        bank_transaction_type_id: "C",
        bank_transaction_type_name: "CEK"
      },
      {
        bank_transaction_type_id: "IB",
        bank_transaction_type_name: "INTERNET BANKING"
      },
      {
        bank_transaction_type_id: "T",
        bank_transaction_type_name: "TRANSFER"
      },
      {
        bank_transaction_type_id: "D",
        bank_transaction_type_name: "DEBET"
      }
    ],
    data_transaction_type_combobox: [
      {
        transaction_type_id: "KBKM",
        transaction_type_name: "KAS BESAR"
      },
      {
        transaction_type_id: "KKKM",
        transaction_type_name: "KAS KECIL"
      },
      {
        transaction_type_id: "BM",
        transaction_type_name: "BANK MASUK"
      },
      {
        transaction_type_id: "KBU",
        transaction_type_name: "UMUM"
      }
    ],
    data_voucher_date: {
      bank_transaction_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10)
    },
    add_voucher: {
      workgroup_id: "",
      project_id: "",
      financing_id: "",
      financing_name: "",
      guarantee_id: "",
      bank_id: "",
      account_number: 0,
      cheque_number: "",
      bank_transaction_desc: "",
      bank_transaction_date: new Date().toISOString().substr(0, 10),
      bank_transaction_type: "",
      transaction_type: "",
      amount: 0,
      path_image_transaction_type: null,
      path_image_transaction_type2: null
    },
    add_voucher_not_null: {
      date_voucher: false,
      bank_transaction_date: formatDate()
    },
    submitted: false,
    headers: [],
    editedIndex: -1,
    deletedIndex: -1,
    editedItem: {},
    editedItemDetail: {},
    deletedItem: {},
    deletedItemDetail: {},
    defaultItem: {},
    isFirstLoad: true,
    options: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: true,
      mustSort: false
    },
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    option: {
      locale: "en-US",
      prefix: "",
      suffix: "",
      length: 20,
      precision: 0
    },
    bank_transaction_date: "",
    date: "",
    user_role: "",
    seen_made_from: "",
    seen: "",
    count: "",
    user_id: "",
    user_fullname: "",
    email: "",
    count_escrow_notification: 0,
    count_bank_guarantee_notification: 0,
    count_submission_form_finance_notification: 0,
    loadingTable: true,
    file: null,
    data: []
  }),

  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentUserPersonalInfo",
      "currentUserPhoto"
    ]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    totalNotification: function() {
      let countEscrowNotification = this.count_escrow_notification;
      let countBankGuaranteeNotification = this
        .count_bank_guarantee_notification;
      let countSubmissionFormFinanceNotification = this
        .count_submission_form_finance_notification;
      let totalNotification = 0;

      totalNotification += parseInt(
        countEscrowNotification +
          countBankGuaranteeNotification +
          countSubmissionFormFinanceNotification
      );

      return totalNotification;
    },

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      let result = "";

      if (this.editedIndex === -1) {
        result = "BUAT VOUCHER";
      } else if (this.editedIndex === -2) {
        result = "UBAH VOUCHER DETAIL";
      } else if (this.editedIndex === -4) {
        result = "BUKTI TRANSFER";
      } else {
        result = "UBAH VOUCHER";
      }

      return result;
    },

    formInput() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_voucher;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formInputNotClear() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.add_voucher_not_null;
      } else {
        result = this.editedItem;
      }

      return result;
    },

    formSubmit() {
      let result = "";

      if (this.editedIndex === -1) {
        result = this.createDataVoucher;
      } else {
        result = this.updateDataVoucher;
      }

      return result;
    },

    computedDate() {
      return this.formatDate(this.bank_transaction_date);
    },

    computedDateModified() {
      return this.formatDate(this.date);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },

    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDoMasuk();
        this.loading = false;
      },
      deep: true
    },

    bank_transaction_date() {
      this.formInputNotClear.bank_transaction_date = this.formatDate(
        this.bank_transaction_date
      );
    },

    date() {
      this.formInputNotClear.date = this.formatDate(this.date);
    }
  },

  created() {
    this.initHeders();
  },

  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });

    this.load();

    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);

    this.user_id = localStorage.getLocalStorage("UID");
    this.user_fullname = localStorage.getLocalStorage("User_Fullname");
    this.email = localStorage.getLocalStorage("User_Email");
    this.user_role = localStorage.getLocalStorage("User_Role");

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Voucher" }]);
  },

  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },

    importData() {
      if (!this.file) {
        alert('Please select a file!');
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const fileData = new Uint8Array(reader.result);
        const workbook = read(fileData, { type: 'array' });

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        this.data = utils.sheet_to_json(worksheet);

        axios.post('/api/import', this.data).then(() => {
          alert('Data imported successfully!');
        });
      };

      reader.readAsArrayBuffer(this.file);
    },

    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },

    createImage(file) {
      let reader = new FileReader();

      reader.onload = e => {
        this.add_voucher.path_image_transaction_type2 = e.target.result;
        this.count = this.add_voucher.path_image_transaction_type2.length;
      };

      reader.readAsDataURL(file);
    },

    onImageChange(file) {
      this.createImage(file);
    },

    getHeaders() {
      return [
        {
          text: "Id",
          sortable: false,
          value: "bank_transaction_id",
          align: " d-none"
        },
        { text: "NO", value: "number", width: "80px" },
        {
          text: "NO VOUCHER",
          sortable: true,
          value: "no_voucher",
          width: "150px"
        },
        {
          text: "TGL TRANSFER",
          sortable: true,
          value: "bank_transaction_date",
          width: "160px"
        },
        {
          text: "PERUSAHAAN",
          sortable: true,
          value: "workgroup_name",
          width: "150px"
        },
        {
          text: "PROYEK",
          sortable: true,
          value: "project_name",
          width: "130px"
        },
        {
          text: "COST",
          sortable: true,
          value: "financing_name",
          width: "120px"
        },
        {
          text: "DESKRIPSI",
          sortable: true,
          value: "bank_transaction_desc",
          width: "140px"
        },
        {
          text: "BANK",
          sortable: true,
          value: "bank_name",
          width: "110px"
        },
        {
          text: "NO REKENING",
          sortable: true,
          value: "account_number",
          width: "150px"
        },
        {
          text: "JENIS",
          sortable: true,
          value: "bank_transaction_type",
          width: "110px"
        },
        {
          text: "NOMINAL",
          sortable: true,
          value: "amount",
          width: "130px"
        },
        {
          text: "TIPE TRANSAKSI",
          sortable: true,
          value: "transaction_type",
          width: "170px"
        },
        {
          text: "BUKTI TRANSFER",
          sortable: true,
          value: "path_image_transaction_type",
          width: "180px"
        },
        {
          text: "DIPOSTING OLEH",
          sortable: true,
          value: "posted_by",
          width: "170px"
        },
        {
          text: "TANGGAL POSTING",
          sortable: true,
          value: "date_posted",
          width: "190px"
        },
        {
          text: "DISETUJUI OLEH",
          sortable: true,
          value: "approval_by",
          width: "170px"
        },
        {
          text: "TANGGAL DISETUJUI",
          sortable: true,
          value: "date_approval",
          width: "200px"
        },
        {
          text: "EDIT",
          value: "aksi",
          sortable: false,
          width: "180px"
        }
      ];
    },

    loadDate: function() {
      this.bank_transaction_date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.bank_transaction_date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    loadDateModified: function() {
      this.date = new Date().toISOString().substr(0, 10);
      this.formInputNotClear.date = this.formatDate(
        new Date().toISOString().substr(0, 10)
      );
    },

    formatDate(date) {
      if (!date) return null;

      var arr = date.split("-"),
        year = arr[0],
        month = arr[1],
        day = arr[2];
      return `${day}-${month}-${year}`;
    },

    parseDate: function(date) {
      if (!date) return null;

      var arr = date.split("/"),
        day = arr[0],
        month = arr[1],
        year = arr[2];
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    onChangeDate(item) {
      this.$refs.date_voucher.save(item);
      this.data_voucher_date.bank_transaction_date = item;
    },

    createDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: this.add_voucher.workgroup_id,
          project_id: this.add_voucher.project_id,
          financing_id: this.add_voucher.financing_id,
          bank_id: this.add_voucher.bank_id,
          account_number: this.add_voucher.account_number,
          cheque_number: this.add_voucher.cheque_number,
          bank_transaction_desc: this.add_voucher.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.bank_transaction_date,
          bank_transaction_type: this.add_voucher.bank_transaction_type,
          bank_transaction_permission: "BK",
          transaction_type: this.add_voucher.transaction_type,
          reference: "",
          amount: this.add_voucher.amount,
          path_image_transaction_type: this.add_voucher
            .path_image_transaction_type,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 1,
          status: 1,
          destination: "bank-transaction"
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MENAMBAH DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.save("add_voucher");
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataLargeCashbankIn(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "MUTATION",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          cashbank_desc: item.bank_transaction_desc,
          special_cashbank_desc: "",
          cashbank_date: this.data_voucher_date.date,
          cashbank_type: "KBM",
          cashbank_permission: "U",
          transaction_type: "",
          reference: item.reference,
          amount: item.amount,
          created_by: item.created_by,
          status: 1,
          account_type: 'KB'
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.save("add_voucher");
            this.asyncUpdateDataVoucherPostStatus(item);
          },
          err => {
            err;
          }
        );
      });
    },

    createDataSmallCashbankIn(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "FROM_BANK_OUT_MUTATION",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          cashbank_desc: item.bank_transaction_desc,
          special_cashbank_desc: "",
          cashbank_date: this.data_voucher_date.date,
          cashbank_type: "KKM",
          cashbank_permission: "U",
          transaction_type: "",
          reference: item.reference,
          amount: item.amount,
          created_by: item.created_by,
          status: 1,
          account_type: 'KK',
          bank_transaction_id: item.bank_transaction_id,
          posted_by: localStorage.getLocalStorage("User_Fullname"),
          date_posted: this.data_voucher_date.date
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/Cashbank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.save("add_voucher");
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataBankIn(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "FROM_BANK_OUT_MUTATION",
          banktransaction_id: item.bank_transaction_id,
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          tax_invoice_number: item.tax_invoice_number,
          invoice_number: item.invoice_number,
          street_mail_number: item.street_mail_number,
          bank_transaction_desc: item.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.date,
          bank_transaction_permission: "BM",
          amount: item.amount,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 0,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENAMBAH DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MENAMBAH DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.create_data_voucher = response.data;
            this.submitted = true;
            this.save("add_voucher");
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    createDataBankGuarantee(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          guarantee_id: item.guarantee_id,
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          guarantee_permission: "BGO",
          guarantee_type: "I",
          guarantee_date: this.data_voucher_date.date,
          total_amount: item.amount,
          total_paid: item.amount,
          total_received: item.amount,
          date_published: this.data_voucher_date.date,
          date_end: this.data_voucher_date.date,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          status_cashing: 0,
          upload_status: 1,
          status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.create_data_bank_guarantee = response.data;
            this.submitted = true;
          },
          err => {
            err;
          }
        );
      });
    },

    createDataVoucherBankIn(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "C",
          Token: "C",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          workgroup_id: item.workgroup_id,
          project_id: item.project_id,
          bank_id: item.bank_id,
          account_number: item.account_number,
          tax_invoice_number: "",
          invoice_number: "",
          street_mail_number: "",
          bank_transaction_desc: item.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.date,
          bank_transaction_permission: "BM",
          amount: item.amount,
          created_by: localStorage.getLocalStorage("User_Fullname"),
          approval_status: 0,
          upload_status: 2,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            this.create_data_voucher = response.data;
            this.submitted = true;
          },
          err => {
            err;
          }
        );
      });
    },

    importDataVoucher() {
      return new Promise(resolve => {
        if (!this.file) {
          alert('Please select a file!');
          return;
        }

        const reader = new FileReader();

        reader.onload = () => {
          const fileData = new Uint8Array(reader.result);
          const workbook = read(fileData, { type: 'array' });

          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          this.data = utils.sheet_to_json(worksheet);

          var mydata = {
            Trigger: "C",
            Token: "C",
            UID: localStorage.getLocalStorage("UID"),
            Route: "IMPORT_EXCEL",
            bank_transaction_date: this.data_voucher_date.date,
            // transaction_type: this.add_voucher.transaction_type,
            created_by: localStorage.getLocalStorage("User_Fullname"),
            approval_status: 0,
            upload_status: 1,
            status: 1,
            data_excel: JSON.stringify(this.data)
          };

          var postdata = new FormData();

          for (var key in mydata) {
            postdata.append(key, mydata[key]);
          }

          // for (const file of this.upload_bill_file.path_file_name) {
          //   postdata.append("path_file_name", file);
          // }

          let contentType = `muntipart/forn-data; boundary=${postdata._boundary}`;

          Services.PostData(
            ApiService,
            "Transaction/BankTransaction",
            postdata,
            contentType,
            response => {
              resolve(response.data);
              if (response.status == 1000) {
                Swal.fire({
                  title: "",
                  text: "BERHASIL MEMPERBAHARUI DATA",
                  icon: "success",
                  heightAuto: true,
                  timer: 1500
                });
              } else {
                Swal.fire({
                  title: "",
                  text: response.message,
                  icon: "info",
                  heightAuto: true,
                  timer: 1500
                });
              }
              this.create_data_voucher = response.data;
              this.submitted = true;
              this.save("add_voucher");
              this.loadDataVoucher();
            },
            err => {
              err;
            }
          );
        };

        reader.readAsArrayBuffer(this.file);
      });

      // if (!this.file) {
      //   alert('Please select a file!');
      //   return;
      // }

      // const reader = new FileReader();

      // reader.onload = () => {
      //   const fileData = new Uint8Array(reader.result);
      //   const workbook = read(fileData, { type: 'array' });

      //   const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      //   this.data = utils.sheet_to_json(worksheet);

      //   const instance = axios.create({
      //     baseURL: 'http://192.168.18.67:3000/api/',
      //     headers: {
      //       'Content-Type': 'application/json',
      //       'Accept': '*/*',
      //       'Access-Control-Allow-Origin': '*'
      //     }
      //   });

      //   const data = {
      //     "Trigger": "C",
      //     "Token": "C",
      //     "UID": localStorage.getLocalStorage("UID"),
      //     "Route": "IMPORT_EXCEL",
      //     "data_excel": this.data
      //   };

      //   instance.post('Transaction/BankTransaction', data)
      //   .then(response => {
      //     if (response.status == 200) {
      //       Swal.fire({
      //         title: "",
      //         text: "Berhasil Mengirim Notifikasi",
      //         icon: "success",
      //         heightAuto: true,
      //         timer: 1500
      //       });
      //     } else {
      //       Swal.fire({
      //         title: "",
      //         text: response.message,
      //         icon: "info",
      //         heightAuto: true,
      //         timer: 1500
      //       });
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
      // };

      // reader.readAsArrayBuffer(this.file);
    },

    updateDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_id: this.editedItem.bank_transaction_id,
          no_voucher: this.editedItem.no_voucher,
          workgroup_id: this.editedItem.workgroup_id,
          project_id: this.editedItem.project_id,
          financing_id: this.editedItem.financing_id,
          bank_id: this.editedItem.bank_id,
          account_number: this.editedItem.account_number,
          cheque_number:
            this.editedItem.bank_transaction_type != "C"
              ? ""
              : this.editedItem.cheque_number,
          bank_transaction_desc: this.editedItem.bank_transaction_desc,
          bank_transaction_date: this.data_voucher_date.bank_transaction_date,
          bank_transaction_type: this.editedItem.bank_transaction_type,
          bank_transaction_permission: "BK",
          transaction_type: this.editedItem.transaction_type,
          reference: "",
          amount: this.editedItem.amount,
          path_image_transaction_type: this.editedItem
            .path_image_transaction_type,
          modified_by: localStorage.getLocalStorage("User_Fullname"),
          date_modified: this.data_voucher_date.date,
          status: 1
        };

        var postdata = new FormData();

        for (var key in mydata) {
          postdata.append(key, mydata[key]);
        }

        let contentType = `multipart/form-data; boundary=${postdata._boundary}`;

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          postdata,
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MEMPERBAHARUI DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataVoucherApproval(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "APPROVAL",
          bank_transaction_id: item.bank_transaction_id,
          approval_by: localStorage.getLocalStorage("User_Fullname"),
          date_approval: this.data_voucher_date.date,
          approval_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            this.loadDataVoucher();
          },
          err => {
            err;
          }
        );
      });
    },

    updateDataVoucherPostStatus(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "U",
          Token: "U",
          UID: localStorage.getLocalStorage("UID"),
          Route: "POST_STATUS",
          bank_transaction_id: item.bank_transaction_id,
          posted_by: localStorage.getLocalStorage("User_Fullname"),
          date_posted: this.data_voucher_date.date,
          post_status: 1
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MEMPERBAHARUI DATA",
                icon: "success",
                heightAuto: false,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: "GAGAL MEMPERBAHARUI DATA",
                icon: "info",
                heightAuto: false,
                timer: 1500
              });
            }
            this.update_data_voucher = response.data;
            this.submitted = true;
            this.save("edit_voucher");
            console.log(item);
            this.asyncCreateDataBankGuarantee(item);
          },
          err => {
            err;
          }
        );
      });
    },

    deleteDataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "D",
          Token: "D",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_id: this.deletedItem.bank_transaction_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            if (response.status == 1000) {
              Swal.fire({
                title: "",
                text: "BERHASIL MENGHAPUS DATA",
                icon: "success",
                heightAuto: true,
                timer: 1500
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "info",
                heightAuto: true,
                timer: 1500
              });
            }
            this.delete_data_voucher = response.data;
            this.submitted = true;
            this.data_voucher.splice(this.deletedIndex, 1);
            this.closeDelete();
          },
          err => {
            err;
          }
        );
      });
    },

    seenTransactionType(item) {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT_FILES_TRANSACTION_TYPE",
          bank_transaction_id: item.bank_transaction_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_files = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataVoucher() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "DEFAULT",
          bank_transaction_permission: "BK"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_voucher = response.data;
            console.log(this.data_voucher);
            this.loadingTable = false;
          },
          err => {
            err;
          }
        );
      });
    },

    dataWorkgroup_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Workgroup",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_workgroup_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataProject_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          workgroup_id: this.formInput.workgroup_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Project",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_project_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBank_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Bank",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankAccount_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          bank_id: this.formInput.bank_id
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/BankAccount",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_account_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataCheque_Combobox() {
      return new Promise(resolve => {
        if (this.seen_made_from == 'import') {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX",
            bank_id: this.formInput.bank_id
          };
        } else {
          var mydata = {
            Trigger: "R",
            Token: "R",
            UID: localStorage.getLocalStorage("UID"),
            Route: "COMBOBOX",
            bank_id: this.formInput.bank_id,
            used_status: 0,
            void_status: 0
          };
        }

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Cheque",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_cheque_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataFinancings_Combobox() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "COMBOBOX",
          financing_type: "Cost"
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Master/Financings",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_financings_combobox = response.data;
          },
          err => {
            err;
          }
        );
      });
    },

    dataEscrowPaymentAccepted() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "ESCROW_PAYMENT_ACCEPTED",
          payment_accepted: "E",
          status_receipt: 1,
          status_escrow_accepted: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankTransaction",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_escrow_notification = response.data;
            this.count_escrow_notification =
              this.user_role.toLowerCase() === "atasan keuangan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataBankGuaranteeNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_BANK_GUARANTEE",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/BankGuarantee",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_bank_guarantee_notification = response.data;
            this.count_bank_guarantee_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    dataSubmissionFormNotification() {
      return new Promise(resolve => {
        var mydata = {
          Trigger: "R",
          Token: "R",
          UID: localStorage.getLocalStorage("UID"),
          Route: "NOTIFICATION_FOR_BANK_GUARANTEE",
          submission_type: "BGOI",
          status_cashing: 0
        };

        let contentType = `application/x-www-form-urlencoded`;

        const qs = require("qs");

        Services.PostData(
          ApiService,
          "Transaction/SubmissionForm",
          qs.stringify(mydata),
          contentType,
          response => {
            resolve(response.data);
            this.data_submission_form_finance_notification = response.data;
            this.count_submission_form_finance_notification =
              this.user_role.toLowerCase() === "atasan"
                ? response.data.length
                : 0;
          },
          err => {
            err;
          }
        );
      });
    },

    async load() {
      Promise.all([
        //await this.dataEscrowPaymentAccepted(),
        //await this.dataBankGuaranteeNotification(),
        //await this.dataSubmissionFormNotification(),
        await this.dataVoucher()
      ]).then(function(results) {
        results;
      });
    },

    async loadDataVoucher() {
      Promise.all([await this.dataVoucher()]).then(function(results) {
        results;
      });
    },

    async loadCombobox() {
      Promise.all([
        await this.dataWorkgroup_Combobox(),
        await this.dataProject_Combobox(),
        await this.dataBank_Combobox(),
        await this.dataBankAccount_Combobox(),
        await this.dataCheque_Combobox()
      ]).then(function(results) {
        results;
      });
    },

    async asyncUpdateDataVoucherPostStatus(item) {
      Promise.all([await this.updateDataVoucherPostStatus(item)]).then(function(
        results
      ) {
        results;
      });
    },

    async asyncCreateDataBankGuarantee(item) {
      if (item.bank_transaction_type === "U") {
        Promise.all([
          await this.createDataBankGuarantee(item),
          await this.dataVoucher()
        ]).then(function(results) {
          results;
        });
      } else {
        Promise.all([await this.dataVoucher()]).then(function(results) {
          results;
        });
      }
    },

    seenFileTransactionType(item) {
      this.editedIndex = -4;
      this.seenTransactionType(item);
      this.dialogSeenTransactionType = true;
    },

    clickPosted(item) {
      console.log(item.transaction_type);
      if (item.transaction_type === 'KBKM') {
        this.createDataLargeCashbankIn(item);
      } else if (item.transaction_type === 'KKKM') {
        this.createDataSmallCashbankIn(item);
      } else if (item.transaction_type === 'BM') {
        this.createDataBankIn(item);
      } else if (item.transaction_type === 'KBU') {
        this.updateDataVoucherPostStatus(item);
      }
    },

    editItem(item) {
      this.editedIndex = this.data_voucher.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.loadCombobox();
      this.seen = item.bank_transaction_type;
      this.seen_made_from = item.made_from;
      this.dialog = true;
    },

    deleteItem(item) {
      this.deletedIndex = this.data_voucher.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.data_voucher.splice(this.deletedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.add_voucher.workgroup_id = "";
        this.add_voucher.project_id = "";
        this.add_voucher.financing_id = "";
        this.add_voucher.bank_transaction_desc = "";
        this.add_voucher.bank_id = "";
        this.add_voucher.account_number = "";
        this.add_voucher.bank_transaction_type = "";
        this.add_voucher.cheque_number = "";
        this.add_voucher.path_image_transaction_type = null;
        this.add_voucher.path_image_transaction_type2 = null;
        this.add_voucher.amount = 0;
        this.add_voucher.transaction_type = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deletedItem = Object.assign({}, this.defaultItem);
        this.deletedIndex = -1;
      });
    },

    save(formInput) {
      if (formInput == "add_voucher") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_voucher[this.editedIndex], this.add_voucher);
        } else {
          this.data_voucher.push(this.add_voucher);
        }
      } else if (formInput == "edit_voucher") {
        if (this.editedIndex > -1) {
          Object.assign(this.data_voucher[this.editedIndex], this.editedItem);
        } else {
          this.data_voucher.push(this.editedItem);
        }
      }
      this.close();
    },

    initHeders() {
      this.headers = this.getHeaders();
    },

    seenDetailType() {
      this.seen = this.formInput.bank_transaction_type;
    },

    formatNumber
  }
};
</script>
